import React from 'react'
import louge1 from '../../assets/images/louge-01.png'
import louge2 from '../../assets/images/louge-02.png'
import louge3 from '../../assets/images/louge-03.png'

const louges = [
  {
    type: 'AWS Certification Lounge',
    description: 'Are you already AWS Certified?  Yes 👉 Stop by our AWS Certification Lounge to relax & network with other certified professionals in your area! Not yet  👉 Pop by the booth & we are happy to answer any questions you might have during AWS UG Conf Perú 🇵🇪! ',
    image: louge1
  },

  {
    type: 'AWS Community Lounge',
    description: 'The AWS User Groups will be present during the AWS UG Conf Perú 🇵🇪. It is like a "Meetup of Meetups". The goal of the Community Track is to facilitate the exchange of information amongst the AWS user community.',
    image: louge2
  },
  {
    type: 'AWS Startup Lounge',
    description: 'We have talks for startup founders & developers, our ask an expert bar to get your technical questions answered, and a lounge to hang out and meet other startups & AWS startups staff.',
    image: louge3
  }
]

export function Lounge () {
  return (
    <section className="why-choose-us-two">
      <div className="row m-0 h-100 align-items-center">

        {
          louges.map((louge, index) => {
            return (
              <><div key={`louge-img-${index}`} className="col-lg-4 col-md-6 p-0">
                <div className="why-choose-img">
                  <img src={louge.image} alt={louge.type} />
                </div>
              </div><div key={`louge-${index}`}className="col-lg-4 col-md-6 p-0">
                <div className="why-choose-content">
                  <h3>{louge.type}</h3>
                  <p>{louge.description}</p>
                  <span>{`0${index + 1}`}</span>
                </div>
              </div></>
            )
          })
        }
      </div>
    </section>
  )
}
