import React from 'react'
import lax from 'lax.js'
import '../../assets/css/conf-personal.css'

class About extends React.Component {
  constructor (props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render () {
    return (
      <section className="about-area ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                <span>Join The Event</span>
                <h2>
                      Why should you <b>participate</b> in this event?
                </h2>
                <p className="font-p jus">
                      The annual cloud technology conference on Amazon Web
                      Services in Peru, exclusively organized by the AWS User
                      Group Peru, brings together top executives and cloud
                      computing and IT companies from Peru and throughout Latin
                      America.
                </p>
                <p className="font-p jus">
                      Attendees will not only receive information and learn
                      about market trends and new solutions in cloud and Amazon
                      Web Services, but they will also have the opportunity to
                      interact directly with leading brands in the market.
                </p>

                {/* <Link to="/about-3" className="btn btn-primary">
                      Read More
                      <i className="icofont-double-right"></i>
                    </Link> */}

                {/* <Link to="#" className="btn btn-secondary">
                      Sign Up
                    </Link> */}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image">
                <img
                  src={require('../../assets/images/f7.jpg')}
                  className="about-img1"
                  alt="about"
                />

                <img
                  src={require('../../assets/images/f18.jpg')}
                  width={510}
                  className="about-img2"
                  alt="about"
                />

                {/* <img
                      src={require("../../assets/images/shapes/5.png")}
                      className="shape-img"
                      alt="about"
                    /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About
