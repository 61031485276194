import React from 'react'

export function Video (props) {
  return (
    <video
      loop
      muted
      autoPlay
      className="w-100"
    >
      <source src={props.src} type="video/mp4"/>
        Sorry, your browser does not support embedded videos.
    </video>
  )
}
