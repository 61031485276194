import React from 'react'
import MainBanner from '../HomeFive/MainBanner'
import About from '../HomeDefault/About'
import About2 from '../HomeDefault/About2.js'
import WhyUs from '../Common/WhyUs'
import Speakers from '../HomeDefault/Speakers'
import GoTop from '../Shared/GoTop'
import Footer from '../Common/Footer'
import EventSchedules from '../HomeDefault/EventSchedules'
import FunFact from '../Common/FunFact'
import Pricing from '../HomeDefault/Pricing'
import lax from 'lax.js'
// import Partner from '../Common/Partner'
// import Partner2 from '../Common/Partner2'
// import Partner3 from '../Common/Partner3'
import Partner4 from '../Common/Partner4.jsx'
import { SessionLevel } from '../Common/SessionLevel'
import { Serverlesspresso } from '../HomeDefault/Serverlesspresso'
import { Lounge } from '../HomeDefault/Lounge'
import { LoungeS } from '../HomeDefault/LoungeS.jsx'

class HomeDefault extends React.Component {
  constructor (props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    this.state = {
      showPopup: true
    }

    this.closePopup = this.closePopup.bind(this)
    lax.update(window.scrollY)
  }

  closePopup () {
    this.setState({ showPopup: false })
  }

  render () {
    return (
      <React.Fragment>
        {/* {this.state.showPopup && <Popup onClose={this.closePopup} />} */}
        {/* Main Banner */}
        <MainBanner />
        {/* About Area */}
        <About />
        <About2 />
        {/* Why Choose Us Area */}
        <WhyUs />
        {/* Speakers Area */}
        <Speakers />
        {/* Schedule Area */}
        <EventSchedules />
        {/* FunFacts Area */}
        <SessionLevel />
        <Serverlesspresso />
        <Lounge />
        <LoungeS />
        <FunFact />
        {/* Pricing Area */}
        <Pricing />
        {/* Partner Area */}
        <Partner4 />
        {/* <Partner /> */}
        {/* <Partner2 /> */}
        {/* <Partner3 /> */}
        {/* Footer Area */}
        <Footer />

        {/* Back Top top */}
        <GoTop scrollStepInPx="50" delayInMs="16.66" />
      </React.Fragment>
    )
  }
}

export default HomeDefault
