import React from 'react'
import '../../assets/css/conf-personal.css'

const levels = [
  {
    title: 'Introductory',
    type: 'Level 100',
    description: 'Sessions are focused on providing an overview of AWS services and features, with the assumption that attendees are new to the topic.'
  },
  {
    title: 'Intermediate',
    type: 'Level 200',
    description: 'Sessions are focused on providing best practices, details of service features and demos with the assumption that attendees have introductory knowledge of the topics.'
  },
  {
    title: 'Advanced',
    type: 'Level 300',
    description: 'Sessions dive deeper into the selected topic. Presenters assume that the audience has some familiarity with the topic, but may or may not have direct experience implementing a similar solution.'
  },
  {
    title: 'Expert',
    type: 'Level 400',
    description: 'Sessions are for attendees who are deeply familiar with the topic, have implemented a solution on their own already, and are comfortable with how the technology works across multiple services, architectures, and implementations.'
  }
]

export function SessionLevel () {
  return (
    <section className="why-choose-us bg-dark">
      <div className="container pt--40">
        <div className="section-title">
          <h2 className="fc--white">Session Levels</h2>
          <div className="bar"></div>
        </div>
      </div>
      <div className="container">
        <div className="row m-0">
          {
            levels.map((level, index) => {
              return (
                <div key={`level-${index}`} className="col-lg-3 col-sm-6 p-0">
                  <div className="single-box">
                    <div className="d-table">
                      <div className="d-table-cell">
                        <div className="content">
                          <div className="icon">
                            <h1 className="colorw">{level.title} </h1>
                          </div>
                          <h3 className="color_secondary">{level.type}</h3>
                          <p className="fs_12 ta_justify">
                            {level.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}
