import React from 'react'
import { Link } from 'react-router-dom' // Agregar importación de Link

import luisBenavides from '../assets/images/luis_benavides.png'
import jhamilArroyo from '../assets/images/jhamil_arroyo.png'
import corradoDaly from '../assets/images/corrado_daly.png'
import dianaAlfaro from '../assets/images/diana_alfaro.png'
import eliasMarcelo from '../assets/images/elias-removebg-preview.png'
import giomarAntaurco from '../assets/images/giomar-removebg-preview.png'
import banderaPeru from '../assets/images/banderaPeru.png'
import edgerQuispe from '../assets/images/edgar-quispe.png'
import joset from '../assets/images/josset.jpeg'
import luismiguel from '../assets/images/luismiguel.png'

const SpeakerList = () => {
  const coorganizers = [
    {
      name: 'Luis Benavides',
      job: 'NodeJs Developer, Globant',
      twitter: 'https://twitter.com/',
      linkedin: 'https://www.linkedin.com/in/luisbenavidesac/',
      photo: luisBenavides,
      flag: banderaPeru
    },
    {
      name: 'Jhamil Arroyo',
      job: 'React Developer',
      twitter: 'https://twitter.com/ArroyoJhamil',
      linkedin: 'https://www.linkedin.com/in/jhamil-arroyo-ayzana-7281a8213/',
      photo: jhamilArroyo,
      flag: banderaPeru
    },
    {
      name: 'Diana Alfaro',
      job: 'AWS Community Builder',
      twitter: 'https://twitter.com/lachamive',
      linkedin: 'https://www.linkedin.com/in/dianaalfarobazan/',
      photo: dianaAlfaro,
      flag: banderaPeru
    },
    {
      name: 'Corrado Daly',
      job: 'Departamento de Ingeniería PUCP',
      twitter: 'https://twitter.com/',
      linkedin: 'https://www.linkedin.com/in/corrado-daly-0bab1433/',
      photo: corradoDaly,
      flag: banderaPeru
    },
    {
      name: 'Elias Marcelo',
      job: 'Estudiante de Diseño Gráfico Digital',
      twitter: 'https://twitter.com/',
      linkedin: 'https://www.linkedin.com/in/elias-marcelo-revilla-alvarado-1013a827a/',
      photo: eliasMarcelo,
      flag: banderaPeru
    },
    {
      name: 'Giomar Antaurco',
      job: 'BI Analyst',
      twitter: 'https://twitter.com/',
      linkedin: 'https://www.linkedin.com/in/giomar-antaurco-trejo/',
      photo: giomarAntaurco,
      flag: banderaPeru
    },
    {
      name: 'Eder Quispe Vilchez',
      job: 'Arquitecto de soluciones tecnológicas Web y Móviles',
      twitter: '',
      linkedin: 'https://www.linkedin.com/in/eder-quispe-vilchez/',
      photo: edgerQuispe,
      flag: banderaPeru
    },
    {
      name: 'Josset Gutierrez Huaman',
      job: 'Cloud Architect & DevOps & SRE',
      twitter: '',
      linkedin: 'https://www.linkedin.com/in/jothanpe/',
      photo: joset,
      flag: banderaPeru
    },
    {
      name: 'Luis Miguel Guerra',
      job: 'Cloud Solution Architect',
      twitter: '',
      linkedin: 'https://www.linkedin.com/in/luism-guerra/',
      photo: luismiguel,
      flag: banderaPeru
    }

  ]
  return (
    <div className="container">
      <div className="row">
        {coorganizers.map((guest, index) => {
          return (
            <div key={`guest-${index}`} className="col-lg-4 col-sm-6 p-0">
              <div className="single-speakers">
                <img
                  src={guest.photo}
                  alt="Speaker"
                  className="photo width-images"
                />

                <div className="speakers-content">
                  <h3 className='row-flag'>
                    <Link to="#">{guest.name}</Link>
                    <img className='flag-size' src={guest.flag} alt="Guest Flag" />
                  </h3>
                  <span>{guest.job}</span>
                  <ul>
                    <li>
                      <a
                        href={guest.twitter}
                        target="blank"
                        className="twitter"
                      >
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={guest.linkedin}
                        target="blank"
                        className="linkedin"
                      >
                        <i className="icofont-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SpeakerList
