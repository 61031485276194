import React, { useEffect } from 'react'
import axios from 'axios'
import lax from 'lax.js'
import LaxDiv from '../Shared/LaxDiv'
// import SpeakerPrincipalList from './SpeakerList.jsx'
import '../../assets/css/conf-personal.css'
import { Link } from 'react-router-dom'

const EventSchedules = () => {
  // const [speakerData, setSpeakerData] = useState([])

  useEffect(() => {
    lax.setup()

    const handleScroll = () => {
      lax.update(window.scrollY)
    }

    document.addEventListener('scroll', handleScroll, false)
    lax.update(window.scrollY)

    // Realizar solicitud a la API para obtener datos de los ponentes
    axios
      .get('https://sessionize.com/api/v2/5qfqbgdp/view/Speakers')
      .then((response) => {
        // Filtrar a Carolina Herrera Monteza y Arturo Martínez V.
        // const filteredSpeakers = response.data.filter(
        //   (speaker) =>
        //     speaker.fullName !== 'Carolina Herrera Monteza' &&
        //     speaker.fullName !== 'Arturo Martínez V.' &&
        //     speaker.fullName !== 'Diana Alfaro' &&
        //     speaker.fullName !== 'Mark Birch'
        // )
        // setSpeakerData(filteredSpeakers)
      })
      .catch((error) => {
        console.error('Error fetching speaker data:', error)
      })

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, []) // Se ejecuta solo una vez al montar el componente

  return (
    <section className="schedule-area bg-image ptb-120" id="schedule">
      <div className="container">
        <div className="section-title row flex-column align-items-center">
          <h2>INFORMATION OF EVENT SCHEDULES</h2>
          <Link target="_blank" to="agenda" className="btn btn-primary changes">
          Agenda ServerlessDays + UGConf
          </Link>
          <LaxDiv text="Events" dataPreset="driftLeft" />
          {/* <SpeakerPrincipalList speakerData={speakerData} /> */}
        </div>
      </div>
      <div className="shape1">
        <img src={require('../../assets/images/shapes/1.png')} alt="shape1" />
      </div>
      <div className="shape2 rotateme">
        <img src={require('../../assets/images/shapes/2.png')} alt="shape2" />
      </div>
      <div className="shape3 rotateme">
        <img src={require('../../assets/images/shapes/3.png')} alt="shape3" />
      </div>
      <div className="shape4">
        <img src={require('../../assets/images/shapes/4.png')} alt="shape4" />
      </div>
    </section>
  )
}

export default EventSchedules
