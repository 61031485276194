import React from 'react'
import lax from 'lax.js'
// import LaxDiv from '../Shared/LaxDiv';
import '../../assets/css/conf-personal.css'

class Pricing extends React.Component {
  constructor (props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render () {
    return (
      <section className="pricing-area ptb-120 bg-image">
        <div className="container">
          <div className="section-title">
            <h2>
                  BECOME A SPONSOR
            </h2>

            <div className="bar"></div>
          </div>

          <div
            className="row
              "
          >
            <div className="col-xl-3 col-md-6 offset-lg-0 offset-md-3">
              <div className="pricing-table-box">
                <div
                  style={{ backgroundColor: '#07dada' }}
                  className="pricingTable-header"
                >
                  <h3 className="title">
                        Sponsor <br /> Diamond
                  </h3>
                </div>

                <ul className="pricing-content">
                  {/* IGUAL TODOS  */}
                </ul>

                <a href="https://www.linkedin.com/in/andrecv22/"
                  target='_blank' className="btn btn-primary padding-2" rel="noreferrer">
                  <div className="icon">
                    <i className='icofont-ui-call'></i>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 offset-lg-0 offset-md-3">
              <div className="pricing-table-box">
                <div
                  style={{ backgroundColor: '#f5d000' }}
                  className="pricingTable-header"
                >
                  <h3 className="title">
                        Sponsor <br /> Gold
                  </h3>
                </div>

                <ul className="pricing-content">
                </ul>

                <a href="https://www.linkedin.com/in/andrecv22/"
                  target='_blank' className="btn btn-primary padding-2" rel="noreferrer">
                  <div className="icon">
                    <i className='icofont-ui-call'></i>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="pricing-table-box">
                <div
                  style={{ backgroundColor: '#b3b3b3' }}
                  className="pricingTable-header"
                >
                  <h3 className="title">
                        Sponsor <br /> Platinum
                  </h3>
                </div>

                <div className="pricing-content">
                </div>

                <a href="https://www.linkedin.com/in/andrecv22/"
                  target='_blank' className="btn btn-primary padding-2" rel="noreferrer">
                  <div className="icon">
                    <i className='icofont-ui-call'></i>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="pricing-table-box">
                <div className="pricingTable-header">
                  <h3 className="title">
                        Sponsor <br /> Bronze
                  </h3>
                </div>

                <ul className="pricing-content">
                </ul>

                <a href="https://www.linkedin.com/in/andrecv22/"
                  target='_blank' className="btn btn-primary padding-2" rel="noreferrer">
                  <div className="icon">
                    <i className='icofont-ui-call'></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Pricing
