import React from 'react'
import lax from 'lax.js'
import '../../assets/css/conf-personal.css'

class About2 extends React.Component {
  constructor (props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render () {
    return (
      <section className="about-area ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                <span>ServerlessDays Lima 2024</span>
                <h2>
                      Learning Opportunities
                </h2>
                <p className="font-p jus">
                No matter your role—whether you are a developer eager to write code, an architect exploring innovative design patterns, or a CTO steering digital transformation—you all discover invaluable insights at our event. Our speakers come from a variety of backgrounds and domains, providing a comprehensive overview of the evolving serverless landscape.
                </p>
                <p className="font-p jus">
                Explore a diverse range of topics at our conference, from the technical intricacies of serverless architecture to its strategic impact on business models. Hear from industry experts who will share their experiences, insights, and lessons learned from real-world serverless projects. Our event features an engaging mix of keynote presentations, interactive workshops, and panel discussions—a perfect blend of theory and practice that sets ServerlessDays Lima apart.
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image">
                <img
                  src={require('../../assets/images/slsdays foto 2.jpeg')}
                  className="about-img1"
                  alt="about"
                />

                <img
                  src={require('../../assets/images/slsdayslima foto previa.png')}
                  width={510}
                  className="about-img2"
                  alt="about"
                />

                {/* <img
                      src={require("../../assets/images/shapes/5.png")}
                      className="shape-img"
                      alt="about"
                    /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About2
