import React from 'react'

const louges = [
  {
    type: 'AWS Builder Cards',
    description:
      'Dive into the world of AWS services with AWS BuilderCards, an exciting role card game that challenges your technical knowledge and strategic thinking. Experience hands-on learning as you tackle real-world scenarios and compete against fellow attendees to design innovative cloud solutions. Whether you are a beginner or an expert, BuilderCards is a fun and engaging way to expand your AWS skills.',
    image:
      'https://ugconf2024-landingpage-assets.s3.us-east-2.amazonaws.com/f15.jpg'
  },

  {
    type: 'AWS Deep Racer',
    description:
    'Take your learning to the fast lane with AWS DeepRacer, an autonomous vehicle racing experience that combines cutting-edge technology with reinforcement learning. Test your skills on the track as you train your model and compete against others in thrilling races. This is a unique opportunity to delve into the world of AI and machine learning in a practical, exhilarating setting.',

    image:
      'https://ugconf2024-landingpage-assets.s3.us-east-2.amazonaws.com/f16.jpg'
  },
  {
    type: 'AWS GameDay',
    description:
    'Put your AWS knowledge to the test in AWS GameDay, a day of chaos engineering that challenges you to maintain system stability in the face of unexpected disruptions. Work with a team to troubleshoot and resolve real-time issues while learning best practices for resilience and scalability. This immersive experience is an excellent way to hone your problem-solving skills and gain invaluable insights into cloud architecture.',
    image:
      'https://ugconf2024-landingpage-assets.s3.us-east-2.amazonaws.com/f3.jpg'
  }
]

export function LoungeS () {
  return (
    <section className="why-choose-us-two">
      <div className="row m-0 h-100 align-items-center">
        {louges.map((louge, index) => {
          return (
            <>
              <div key={`louge-img-${index}`} className="col-lg-4 col-md-6 p-0">
                <div className="why-choose-img">
                  <img src={louge.image} alt={louge.type} />
                </div>
              </div>
              <div key={`louge-${index}`} className="col-lg-4 col-md-6 p-0">
                <div className="why-choose-content">
                  <h3>{louge.type}</h3>
                  <p>{louge.description}</p>
                  <span>{`0${index + 1}`}</span>
                </div>
              </div>
            </>
          )
        })}
      </div>
    </section>
  )
}
