import React from 'react'
import lax from 'lax.js'
import OwlCarousel from 'react-owl-carousel3'
import '../../assets/css/conf-personal.css'

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>"
  ],
  responsive: {
    0: {
      items: 2
    },
    400: {
      items: 3
    },
    576: {
      items: 3
    },
    768: {
      items: 4
    },
    1200: {
      items: 5
    }
  }
}

class Partner extends React.Component {
  constructor (props) {
    super(props)
    lax.setup()

    document.addEventListener('scroll', function (x) {
      lax.update(window.scrollY)
    }, false)

    lax.update(window.scrollY)
  }

  render () {
    return (
      <section className="partner-area ptb-120" id="sponsor">
        <div className="container">
          <div className="section-title">
            {/* <span>Check Who Makes This Event Possible!</span> */}
            <h2>OUR EVENT SPONSORS</h2>

            {/* <Link to="/sponsors" className="btn btn-primary">
                  Become Link Sponsor
                </Link> */}

            <div className="bar"></div>
          </div>

          <div className="row .unic">
            <OwlCarousel
              className="gold-partner-slides owl-carousel owl-theme"
              {...options}
            >

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://www.linkedin.com/company/quiputec/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="build-quiputec"
                      src={require('../../assets/images/Logo-Quiputec.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://www.youtube.com/@imperiocloud"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="build-imperiocloud"
                      src={require('../../assets/images/imperio-cloud.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://amber.pe/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-amber"

                      src={require('../../assets/images/Amber-logo.jpeg')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://funciton.com/es/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-funciton"
                      src={require('../../assets/images/funciton.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://manantial.pe/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-manantial"
                      src={require('../../assets/images/manantial2.jfif')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://www.cloudhesivelatam.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-cloudhesive"

                      src={require('../../assets/images/cloudhesive-logo.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://www.caleidos.pe/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-caleidos"

                      src={require('../../assets/images/Logo-Caleidos-h.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://www.mongodb.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-mongo"

                      src={require('../../assets/images/MongoDB_SpringGreen.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://bigcheese.com.pe/home"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-big-cheese"

                      src={require('../../assets/images/logo-big-cheese.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://www.applying.cloud/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-applying"

                      src={require('../../assets/images/Applying-logo.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://ninjadata.school/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-ninja"

                      src={require('../../assets/images/Ninja-p.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://newrelic.com/es"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-newRelic"

                      src={require('../../assets/images/new_relic_logo.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://www.canvia.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-applying"

                      src={require('../../assets/images/logo-canvia-01.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

            </OwlCarousel>
          </div>
        </div>
      </section>
    )
  }
}

export default Partner
