import React from 'react'
import serverlesspressoVideo from '../../assets/video/serverlesspresso.mp4'
import serverlesspressoLogo from '../../assets/images/serverlesspresso.png'

import '../../assets/css/conf-personal.css'
import { Video } from '../Common/Video'

export function Serverlesspresso () {
  return (
    <section className="about-area ptb-120 bg-image">
      <div className="container">
        <div className="row h-100 align-items-center">
          <div className="col-lg-6">
            <div className="about-content">
              <img src={serverlesspressoLogo} className='to-forty'/>
              <span>Serverlesspresso</span>
              <h2 className='fs--36'>
                    What is <b>Serverlesspresso</b>?
              </h2>
              <p className="font-p jus fs--14">
              Serverlesspresso is an interactive serverless application showcased at AWS re:Invent 2021. It allows you to order coffee from your phone. It consists of three front-end applications and a backend built primarily with AWS Step Functions and Amazon EventBridge. Come by the booth in Dev Lounge and enjoy a complimentary espresso drink, courtesy of your AWS Serverless Developer Advocate team.
              </p>

              <a href="https://da-public-assets.s3.amazonaws.com/serverlessland/pdf/2021+-+Serverlesspresso+exhibit+-+PDF.pdf" target='_blank' rel="noreferrer" className="btn btn-primary">
                Read More
                <i className="icofont-double-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <Video src={serverlesspressoVideo}/>
          </div>
        </div>
      </div>
    </section>
  )
}
