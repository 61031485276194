import React from 'react'
import lax from 'lax.js'
import OwlCarousel from 'react-owl-carousel3'
import '../../assets/css/conf-personal.css'

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>"
  ],
  responsive: {
    0: {
      items: 2
    },
    400: {
      items: 3
    },
    576: {
      items: 3
    },
    768: {
      items: 4
    },
    1200: {
      items: 5
    }
  }
}

class Partner4 extends React.Component {
  constructor (props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }

  render () {
    return (
      <section
        className="partner-area ptb-120"
        id="sponsor"
      >
        <div className="container">
          <div className="section-title">
            <h2>SPONSORS</h2>

            {/* <Link to="/sponsors" className="btn btn-primary">
                  Become Link Sponsor
                </Link> */}

            <div className="bar"></div>
          </div>

          <div className="row .unic">
            <OwlCarousel
              className="gold-partner-slides owl-carousel owl-theme"
              {...options}
            >

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://amber.pe/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-amber"

                      src={require('../../assets/images/Amber-logo.jpeg')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://manantial.pe/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-manantial"
                      src={require('../../assets/images/manantial2.jfif')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://www.cloudcamp.la/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-big-cheese"

                      src={require('../../assets/images/cloud-camp.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://datapath.ai/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-amber"

                      src={require('../../assets/images/datapath.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://wearebigcheese.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-big-cheese"

                      src={require('../../assets/images/big.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://pe.nttdata.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-amber"

                      src={require('../../assets/images/nttdata.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <a
                    href="https://orion.global/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      className="build-amber"

                      src={require('../../assets/images/orion.png')}
                      alt="Partner Logo"
                    />
                  </a>
                </div>
              </div>

            </OwlCarousel>
          </div>
        </div>
      </section>
    )
  }
}

export default Partner4
