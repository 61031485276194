const sessionize = {}

sessionize.manageActive = function () {
  const n = document.querySelectorAll('[data-szactive]')
  n.forEach(function (n) {
    const t = n.dataset.szactive.split('|')
    const r = new Date(t[0])
    const u = new Date(t[1])
    const i = new Date()
    let f = false
    i.setSeconds(0)
    if (i < r) {
      f = true
      n.classList.add('sz-hidden')
    } else if (i > u) {
      f = true
      n.classList.add('sz-past')
    }
    f || n.classList.add('sz-active')
  })
}

sessionize.getLocalTimes = async function () {
  let timeZone
  try {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    sessionize.localCityName = timeZone.split('/')[1].replace(/_/g, ' ')
    sessionize.localCityName = sessionize.localCityName === 'Kiev' ? 'Kyiv' : sessionize.localCityName
  } catch (error) {
    console.error(error)
    sessionize.showLocalTimezone = false
  }

  if (sessionize.showLocalTimezone) {
    const timezoneElements = document.querySelectorAll('[data-sztz]')
    const timezones = Array.from(timezoneElements).map(element => element.dataset.sztz)
    const formData = new FormData()
    formData.append('timezone', timeZone)
    formData.append('values', timezones)

    try {
      const response = await fetch('https://sessionize.com/api/v2/sztz', {
        method: 'POST',
        body: formData
      })

      if (response.ok) {
        const data = await response.json()
        sessionize.localTimezone = data.localTimezone
        sessionize.hasDifferentTimes = false

        timezoneElements.forEach(element => {
          element.dataset.sztzE = element.innerText
          element.dataset.sztzL = data.values[element.dataset.sztz]
          element.style.opacity = 1
          element.removeAttribute('data-sztz')

          if (element.dataset.sztzE !== element.dataset.sztzL) {
            sessionize.hasDifferentTimes = true
          }
        })

        if (sessionize.hasDifferentTimes) {
          sessionize.showTimes(sessionize.timeMode)
          if (sessionize.eventCityName && sessionize.localCityName && document.querySelector('.sz-timezone')) {
            const localTimezoneRadio = document.querySelector('.sz-timezone .sz-timezone__radio--local')
            const eventTimezoneRadio = document.querySelector('.sz-timezone .sz-timezone__radio--event')

            localTimezoneRadio.querySelector('.sz-timezone__name').innerHTML = sessionize.localCityName
            localTimezoneRadio.querySelector('.sz-timezone__tooltip').innerHTML = sessionize.localTimezone
            localTimezoneRadio.querySelector('input[type="radio"]').onchange = function () {
              sessionize.showTimes('local')
            }

            eventTimezoneRadio.querySelector('.sz-timezone__name').innerHTML = sessionize.eventCityName
            eventTimezoneRadio.querySelector('.sz-timezone__tooltip').innerHTML = sessionize.eventTimezone
            eventTimezoneRadio.querySelector('input[type="radio"]').onchange = function () {
              sessionize.showTimes('event')
            }

            document.querySelector('.sz-timezone').style.display = ''
          }
        }
      } else if (response.status === 404 || response.status === 500) {
        timezoneElements.forEach(element => {
          element.style.opacity = 1
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
}

sessionize.showModal = async function (n, t, i) {
  const r = document.getElementById('sz-modal-container')
  r.innerHTML = '<div class="sz-modal-overlay"><div class="sz-spinner"></div></div>'
  r.classList.remove('is-hidden')

  try {
    const response = await fetch(`https://sessionize.com/api/v2/${n}/${t}?id=${i}`, {
      method: 'POST'
    })

    if (response.ok) {
      const html = await response.text()
      r.innerHTML = html
      r.classList.remove('is-hidden')
      const closeButton = document.getElementsByClassName('sz-modal__close-on-click')
      for (let n = 0; n < closeButton.length; n++) {
        closeButton[n].onclick = function () {
          document.getElementById('sz-modal-container').classList.add('is-hidden')
        }
      }
      sessionize.getLocalTimes()
      sessionize.manageActive()
    } else if (response.status === 404 || response.status === 500) {
      r.classList.add('is-hidden')
    }
  } catch (error) {
    console.error(error)
  }

  return false
}

export default sessionize
